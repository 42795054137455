import React from "react";
import styled from "styled-components";

const GithubButtonStyle = styled.button`
    height: max(30px, calc((49 / 1440) * 100vw));
    width: max(30px, calc((49 / 1440) * 100vw));

    background: #a7c0bc;
    border-radius: calc((4 / 1440) * 100vw);
    border: none;

    &:hover {
        background: #78948f;
        color: #a7c0bc;
        border: calc((0.4 / 1440) * 100vw) solid #a7c0bc;
        cursor: pointer;
    }
    transition: all 0.8s ease;
`;

const GithubSVGStyle = styled.svg`
    width: max(20px, calc((32 / 1440) * 100vw));
    height: max(20px, calc((32 / 1440) * 100vw));
`;

const GithubButton = () => {
    return (
        <a href="https://github.com/olivervz">
            <GithubButtonStyle>
                <GithubSVGStyle
                    viewBox="0 0 39 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M19.5 0C8.72625 0 0 8.72625 0 19.5C0 28.1287 5.58187 35.4169 13.3331 38.0006C14.3081 38.1713 14.6737 37.5863 14.6737 37.0744C14.6737 36.6113 14.6494 35.0756 14.6494 33.4425C9.75 34.3444 8.4825 32.2481 8.0925 31.1512C7.87312 30.5906 6.9225 28.86 6.09375 28.3969C5.41125 28.0312 4.43625 27.1294 6.06938 27.105C7.605 27.0806 8.70188 28.5187 9.0675 29.1037C10.8225 32.0531 13.6256 31.2244 14.7469 30.7125C14.9175 29.445 15.4294 28.5919 15.99 28.1044C11.6512 27.6169 7.1175 25.935 7.1175 18.4762C7.1175 16.3556 7.87313 14.6006 9.11625 13.2356C8.92125 12.7481 8.23875 10.7494 9.31125 8.06812C9.31125 8.06812 10.9444 7.55625 14.6737 10.0669C16.2337 9.62813 17.8913 9.40875 19.5488 9.40875C21.2063 9.40875 22.8638 9.62813 24.4238 10.0669C28.1531 7.53188 29.7863 8.06812 29.7863 8.06812C30.8588 10.7494 30.1763 12.7481 29.9813 13.2356C31.2244 14.6006 31.98 16.3312 31.98 18.4762C31.98 25.9594 27.4219 27.6169 23.0831 28.1044C23.79 28.7137 24.3994 29.8838 24.3994 31.7119C24.3994 34.32 24.375 36.4163 24.375 37.0744C24.375 37.5863 24.7406 38.1956 25.7156 38.0006C29.5867 36.6938 32.9505 34.2059 35.3336 30.8871C37.7167 27.5683 38.999 23.5857 39 19.5C39 8.72625 30.2738 0 19.5 0Z"
                        fill="#3B5954"
                    />
                </GithubSVGStyle>
            </GithubButtonStyle>
        </a>
    );
};

export default GithubButton;
